import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import zero0 from "./../../content/assets/equations/complicated-equation-that-equals-0 (1).png";
import zero1 from "./../../content/assets/equations/complicated-equation-that-equals-0 (2).png";
import zero2 from "./../../content/assets/equations/complicated-equation-that-equals-0 (3).png";
import zero3 from "./../../content/assets/equations/complicated-equation-that-equals-0 (4).png";
import zero4 from "./../../content/assets/equations/complicated-equation-that-equals-0 (5).png";
import zero5 from "./../../content/assets/equations/complicated-equation-that-equals-0 (6).png";
import zero6 from "./../../content/assets/equations/complicated-equation-that-equals-0 (7).png";
import zero7 from "./../../content/assets/equations/complicated-equation-that-equals-0 (8).png";
import zero8 from "./../../content/assets/equations/complicated-equation-that-equals-0 (9).png";
import zero9 from "./../../content/assets/equations/complicated-equation-that-equals-0 (10).png";
import zero10 from "./../../content/assets/equations/complicated-equation-that-equals-0 (11).png";
import zero11 from "./../../content/assets/equations/complicated-equation-that-equals-0 (12).png";
import zero12 from "./../../content/assets/equations/complicated-equation-that-equals-0 (13).png";
import zero13 from "./../../content/assets/equations/complicated-equation-that-equals-0 (14).png";
import zero14 from "./../../content/assets/equations/complicated-equation-that-equals-0 (15).png";
import zero15 from "./../../content/assets/equations/complicated-equation-that-equals-0 (16).png";
import zero16 from "./../../content/assets/equations/complicated-equation-that-equals-0 (17).png";
import zero17 from "./../../content/assets/equations/complicated-equation-that-equals-0 (18).png";

import one1 from "./../../content/assets/equations/complicated-equation-that-equals-1 (1).png";
import one2 from "./../../content/assets/equations/complicated-equation-that-equals-1 (2).png";
import one3 from "./../../content/assets/equations/complicated-equation-that-equals-1 (3).png";
import one4 from "./../../content/assets/equations/complicated-equation-that-equals-1 (4).png";
import one5 from "./../../content/assets/equations/complicated-equation-that-equals-1 (5).png";
import one6 from "./../../content/assets/equations/complicated-equation-that-equals-1 (6).png";
import one7 from "./../../content/assets/equations/complicated-equation-that-equals-1 (7).png";
import one8 from "./../../content/assets/equations/complicated-equation-that-equals-1 (8).png";
import one9 from "./../../content/assets/equations/complicated-equation-that-equals-1 (9).png";
import one10 from "./../../content/assets/equations/complicated-equation-that-equals-1 (10).png";
import one11 from "./../../content/assets/equations/complicated-equation-that-equals-1 (11).png";
import one12 from "./../../content/assets/equations/complicated-equation-that-equals-1 (12).png";
import one13 from "./../../content/assets/equations/complicated-equation-that-equals-1 (13).png";
import one14 from "./../../content/assets/equations/complicated-equation-that-equals-1 (14).png";
import one15 from "./../../content/assets/equations/complicated-equation-that-equals-1 (15).png";
import one16 from "./../../content/assets/equations/complicated-equation-that-equals-1 (16).png";
import one17 from "./../../content/assets/equations/complicated-equation-that-equals-1 (17).png";
import one18 from "./../../content/assets/equations/complicated-equation-that-equals-1 (18).png";
import one19 from "./../../content/assets/equations/complicated-equation-that-equals-1 (19).png";
import one20 from "./../../content/assets/equations/complicated-equation-that-equals-1 (20).png";

class Equations extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Hard complex math equations that equal 0, 1 and 2"
          keywords={[`math equation generator`, `complex math equations`, `complicated math equation`, `react`]}
          description="Hard complex and complicated math equations that equal 0 and 1"
        />

        <div className="blogpost-content">
        <div className = "blog-body">
            <h2> Complicated math equations</h2>
            <p> These are some examples of hard, complicated and complex math equations that evaluate to 0 and 1.
                These random math problems are generated with <a href="../num2math">num2math, a complicated math expression generator</a>
            </p>

            <h4> Complicated difficult long equations that equals 0</h4>
            <hr></hr>
            <img src={zero0} alt="Complicated hard long math equation that equals 0"/>
            <hr></hr>
            <img src={zero1} alt="Complicated hard long math equation that equals 0"/>
            <hr></hr>
            <img src={zero2} alt="Complicated hard long math equation that equals 0"/>
            <hr></hr>
            <img src={zero3} alt="Complicated hard long math equation that equals 0"/>
            <hr></hr>
            <img src={zero4} alt="Complicated hard long math equation that equals 0"/>
            <hr></hr>
            <img src={zero5} alt="Complicated hard long math equation that equals 0"/>
            <hr></hr>
            <img src={zero6} alt="Complicated hard long math equation that equals 0"/>
            <hr></hr>
            <img src={zero7} alt="Complicated hard long math equation that equals 0"/>
            <hr></hr>
            <img src={zero8} alt="Complicated hard long math equation that equals 0"/>
            <hr></hr>
            <img src={zero9} alt="Complicated hard long math equation that equals 0"/>
            <hr></hr>
            <img src={zero10} alt="Complicated hard long math equation that equals 0"/>
            <hr></hr>
            <img src={zero11} alt="Complicated hard long math equation that equals 0"/>
            <hr></hr>
            <img src={zero12} alt="Complicated hard long math equation that equals 0"/>
            <hr></hr>
            <img src={zero13} alt="Complicated hard long math equation that equals 0"/>
            <hr></hr>
            <img src={zero14} alt="Complicated hard long math equation that equals 0"/>
            <hr></hr>
            <img src={zero15} alt="Complicated hard long math equation that equals 0"/>
            <hr></hr>
            <img src={zero16} alt="Complicated hard long math equation that equals 0"/>
            <hr></hr>
            <img src={zero17} alt="Complicated hard long math equation that equals 0"/>

            <h4> Complicated difficult long equations that equals 1</h4>
            <hr></hr>
            <img src={one1} alt="Complicated hard long math equation that equals 1"/>
            <hr></hr>
            <img src={one2} alt="Complicated hard long math equation that equals 1"/>
            <hr></hr>
            <img src={one3} alt="Complicated hard long math equation that equals 1"/>
            <hr></hr>
            <img src={one4} alt="Complicated hard long math equation that equals 1"/>
            <hr></hr>
            <img src={one5} alt="Complicated hard long math equation that equals 1"/>
            <hr></hr>
            <img src={one6} alt="Complicated hard long math equation that equals 1"/>
            <hr></hr>
            <img src={one7} alt="Complicated hard long math equation that equals 1"/>
            <hr></hr>
            <img src={one8} alt="Complicated hard long math equation that equals 1"/>
            <hr></hr>
            <img src={one9} alt="Complicated hard long math equation that equals 1"/>
            <hr></hr>
            <img src={one10} alt="Complicated hard long math equation that equals 1"/>
            <hr></hr>
            <img src={one11} alt="Complicated hard long math equation that equals 1"/>
            <hr></hr>
            <img src={one12} alt="Complicated hard long math equation that equals 1"/>
            <hr></hr>
            <img src={one13} alt="Complicated hard long math equation that equals 1"/>
            <hr></hr>
            <img src={one14} alt="Complicated hard long math equation that equals 1"/>

            <hr></hr>
            <img src={one15} alt="Complicated hard long math equation that equals 1"/>
            <hr></hr>
            <img src={one16} alt="Complicated hard long math equation that equals 1"/>
            <hr></hr>
            <img src={one17} alt="Complicated hard long math equation that equals 1"/>
            <hr></hr>
            <img src={one18} alt="Complicated hard long math equation that equals 1"/>
            <hr></hr>
            <img src={one19} alt="Complicated hard long math equation that equals 1"/>
            <hr></hr>
            <img src={one20} alt="Complicated hard long math equation that equals 1"/>

            </div>
            </div>

      </Layout>
    )
  }
}

export default Equations

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt(pruneLength: 260)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            categories
          }
        }
      }
    }
  }
`
